
export default {

  async addApplicationAuthorizationUrl(application) {

    const result = await window.$axios({
      url: `/application/${application.id}/authorization/url`,
      method: "POST",
      data: { state: process.env.NODE_ENV },
    });

    return result
  },

  async addApplicationAuthorization(application, authorizationParam) {

    const authorization = await window.$axios({
      url: `/application/${application.id}/authorization`,
      method: "POST",
      data: authorizationParam,
    });

    return authorization
  }


}
