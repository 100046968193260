
export default {

  async getApplication(application) {

    const result = await window.$axios({
      url: `/application/${application.id}`,
      method: 'get',
    })

    return result
  }
}
