<template>
  <div
    id="app"
    :style="{ backgroundColor: $store.state.application.properties.backgroundColor }"
  >
    <!-- <top-title v-if="application.id"></top-title> -->
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      application: (state) => {
        return state.application;
      },
    }),
  },
  data() {
    return {};
  },
  /* async created() {
    await this.$store.dispatch('getApplication')
  },*/
};
</script>


<style lang="scss">
#app {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
}
</style>
