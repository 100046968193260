import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/',
    meta: { requiredAuth: true },
    component: () => import('@/layout'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/Home')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/Login')
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/404')
      },
    ]
  },
  {
    path: '/index',
    name: 'Index',
    redirect: '/index/information',
    component: () => import('@/views/form/Main'),
    children: [
   
      { path: 'information', name: 'Information', meta: { requiredAuth: true }, component: () => import('@/views/form/information/Information') },
      { path: 'questionnaire', name: 'Questionnaire', meta: { requiredAuth: true }, component: () => import('@/views/form/questionnaire/Questionnaire') },
      { path: 'success', name: 'Success', meta: { requiredAuth: true }, component: () => import('@/views/form/success/Success') },
    ]
  },
]

const router = new VueRouter({
  routes
})
export default router
