import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from "vuex-persistedstate";

import UAParser from "ua-parser-js";

import ApplicationService from "../service/application";
import ApplicationAuthorizationService from '../service/application-authorization'
import UserVisitRecordService from '../service/user-visit-record';


Vue.use(Vuex)

const parser = new UAParser()

/**
 * 全局常量
 */
const globalConstant = {
  checkboxTypes: ["MULTIPLE_CHOICE"],
  radioTypes: ["ONE_CHOICE"],
  pickerTypes: [
    "USER_COUNTRY",
    "USER_PROVINCE",
    "USER_CITY",
    "USER_DISTRICT",
  ],
  fieldTypes: [
    "FREE_RESPONSE",
    "USER_NAME",
    "USER_IDENTIFICATION",
    "USER_PHONE",
    "USER_EMAIL",
    "USER_CORPORATION",
    "USER_DUTY",
    "USER_LOCATION",
  ],
}
/**
 * 全局变量
 */
const globalVariable = {
  country: "",
  province: "",
  city: "",
  district: "",
  environment: parser.getResult(),
  source: "PC", //来源默认PC端
  inviter: null, 
  inviteSource: null
}
/**
 * 业务变量
 */
const defaultValue = {
  exhibition: {},
  application: {},
  account: {},
  user: {},
  phone: {},
  visitRecord: {},
  businessCard: {},
}

export default new Vuex.Store({
  state: {
    ...globalConstant,
    ...globalVariable,
    ...defaultValue
  },
  mutations: {
    clearStore: (state) => {
      Object.entries(globalConstant).forEach(
        ([key, value]) => (state[key] = value)
      );
      Object.entries(globalVariable).forEach(
        ([key, value]) => (state[key] = value)
      );
      Object.entries(defaultValue).forEach(
        ([key, value]) => (state[key] = value)
      );
    },
    setCountry: (state, payload) => {
      state.country = payload
    },
    setProvince: (state, payload) => {
      state.province = payload
    },
    setCity: (state, payload) => {
      state.city = payload
    },
    setDistrict: (state, payload) => {
      state.district = payload
    },
    setExhibition: (state, payload) => {
      state.exhibition = {
        ...state.exhibition,
        ...payload
      }
    },
    setApplication: (state, payload) => {
      state.application = {
        ...state.application,
        ...payload
      }
    },
    setAccount: (state, payload) => {
      state.account = {
        ...state.account,
        ...payload
      }
    },
    setUser: (state, payload) => {
      state.user = {
        ...state.user,
        ...payload
      }
    },
    setPhone: (state, payload) => {
      state.phone = {
        ...state.phone,
        ...payload
      }
    },
    setVisitRecord: (state, payload) => {
      state.visitRecord = {
        ...state.visitRecord,
        ...payload
      }
    },
    setBusinessCard: (state, payload) => {
      state.businessCard = {
        ...state.businessCard,
        ...payload
      }
    },
  },
  actions: {
    async getApplication({ commit, state }, params) {

      const applicationParam = params.id ? params : state.application
      const application = await ApplicationService.getApplication(applicationParam);

      commit('setApplication', application)
      commit('setExhibition', application.exhibition);
    },

    async addAuthorization({ commit, state }, params) {

      const applicationParam = params && params.application ? params.application : state.application

      const authorization = await ApplicationAuthorizationService.addApplicationAuthorization(applicationParam, params.data)

      console.log(authorization);
     
      if (authorization.account) {
        commit('setAccount', authorization.account)
        commit('setUser', authorization.account.user)
      }

      if (authorization.user) {
        commit('setUser', authorization.user)
      }

      if (authorization.phone) {
        commit('setPhone', authorization.phone)
      }

      console.log(state.user);
    },
    async getVisitRecord({ commit, state }, params) {

      const userParam = params && params.user && params.user.id ? params.user : state.user
      const exhibitionParam = params && params.exhibition && params.exhibition.id ? params.exhibition : state.exhibition

      const visitRecords = await UserVisitRecordService.getUserVisitRecord(userParam, exhibitionParam)

      if (visitRecords.length > 0) {
        commit('setVisitRecord', visitRecords[0])
      } else {
        //处理方式后置
        commit('setVisitRecord', {})

        // const error = new Error("")

        // error.code = "404-visitRecord";

        // throw error;
      }
    }
  },
  modules: {
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createPersistedState({ storage: window.sessionStorage })]
    : [
      createLogger(),
      createPersistedState({ storage: window.sessionStorage }),
    ],
})
