
export default {

  async getDomains(domain) {

    const result = await window.$axios({
      url: `/domain`,
      method: 'get',
      params: {
        ...domain
      }
    })

    return result
  }
}
