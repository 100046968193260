import store from '../store'
import router from "./index";

import DomainService from '../service/domain'

router.beforeEach(async (to, from, next) => {

  console.log(from);
  console.log(to);

  let { applicationId, inviter, inviteSource, source } = getURLParameters(window.location.href);

  let application = store.state.application
  let visitRecord = store.state.visitRecord

  if (inviter) {
    store.state.inviter = inviter
  }

  if (inviteSource) {
    store.state.inviteSource = inviteSource
  }

  if (source) {
    store.state.source = source
  }

  // if (from.path === to.path) {
  //   next()
  //   return
  // }

  const whiteList = ['404']

  if (whiteList.includes(to.name)) {
    next()
    return
  }

  try {
    //应用信息匹配
    //检查优先级 路径参数 > 当前域名 > store > localStore
    console.log(application);
    const applicationParam = {}
    if (!applicationId) {
      //路径没有
      const domain = document.domain

      console.log(domain);

      const domains = await DomainService.getDomains({ name: domain })

      if (domains && domains.length > 0 && domains[0].application && domains[0].application.id) {
        //域名已绑定应用,使用域名指引
        applicationParam.id = domains[0].application.id
        await store.dispatch('getApplication', applicationParam)
      } else {
        //路径没有 ,域名未绑定
        if (!application.properties) {
          //路径没有,store没有
          if (!localStorage.getItem('application')) {
            ///路径没有,store也没有,localStore也没有
            const error = new Error('无法确定应用')
            error.code = 404
            throw error;
          } else {
            //路径没有,store也没有,local有
            applicationParam.id = localStorage.getItem('application')
            await store.dispatch('getApplication', applicationParam)
          }
        } else {
          //store有
          //不操作
          // applicationParam.id = application.id
        }
      }
    } else {
      //路径有
      applicationParam.id = to.query.applicationId
      await store.dispatch('getApplication', applicationParam)
    }

    application = store.state.application

    //设置网页tilte，为应用名
    document.title = application.name || ""

    if (to.path === '/') {
      //根路径检查
      if (store.state.user.id) {
        //检查预登记进度
        await store.dispatch('getVisitRecord')

        visitRecord = store.state.visitRecord

        checkVisitRecord(visitRecord, () => {
          //未验证手机号
          // next({ path: '/login' })
          route(to, { path: '/login' }, next)
        }, () => {
          // 未完善信息
          // next({ path: '/index/information' })
          route(to, { path: '/index/information' }, next)
        }, () => {
          //未填写问卷
          // next({ path: '/index/questionnaire' })
          route(to, { path: '/index/questionnaire' }, next)
        }, () => {
          //预登记完成
          console.log('预登记完成');
          // next({ path: "/index/success" })
          route(to, { path: '/index/success' }, next)
        })
      }
    }

    next();

  } catch (error) {
    console.log(error);

    if (error.code === 404 || (error.response && error.response.status === 404)) {

      next({ path: '/404' })
      return
    }

    if (error.code) {

      switch (error.code) {
        case "404-visitRecord":
          // next({ path: '/login' })
          route(to, { path: '/login' }, next)
          break;
        case "404":
          next({ path: '/404' })
          break;
        default:
          break;
      }

      return
    }
  }
})

const route = (to, target, next) => {

  if (target.path === to.path) {
    next()
  } else {
    next(target)
  }
}


/**
 * 检查参观记录进度,进行不同的操作
 * @param {*} visitRecord 参展记录
 * @param {*} unVerifiedPhone 未验证手机号的回调
 * @param {*} unPerfectInformation 未完善信息的回调
 * @param {*} unRegistered 未填写问卷的回调
 * @param {*} registered 预登记完成的回调
 */
const checkVisitRecord = (visitRecord, unVerifiedPhone, unPerfectInformation, unRegistered, registered) => {

  //是否已经有参展记录
  if (visitRecord.id) {
    //已经有参展记录
    const questionnaires = visitRecord.properties.questionnaires;

    console.log(visitRecord.progress);

    //参展记录-visitReocrd 有三种状态
    switch (visitRecord.progress) {
      case "VERIFIED_PHONE":
        //VERIFIED_PHONE-已验证手机号，未完善信息
        unPerfectInformation()
        return;
      case "PERFECT_INFORMATION":
        //PERFECT_INFORMATION-已完善信息
        unRegistered()
        return;
      case "REGISTERED":
        //REGISTERED-注册成功
        registered()
        return;
      default:
        //兼容老的数据(以后版本删除)
        //判断是否填写用户信息问卷
        console.log(questionnaires);
        if (
          questionnaires.indexOf(
            this.application.properties.userQuestionnaireId
          ) === -1
        ) {
          //没填写
          unPerfectInformation()
          return;
        }
        //判断是否填写调查问卷
        if (
          questionnaires.indexOf(
            this.application.properties.questionnaireId
          ) === -1
        ) {
          //没填写
          unRegistered()
          return;
        }

        //都填写了
        registered()
    }
  } else {
    //没有参展记录
    unVerifiedPhone()
  }
}

const getURLParameters = (url) =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => (
      (a[v.slice(0, v.indexOf("="))] = v
        .slice(v.indexOf("=") + 1)
        .replace(/#.*/g, "")),
      a
    ),
    {}
  );

export default {
  checkVisitRecord: checkVisitRecord
}